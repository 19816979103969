import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Web3 from 'web3';
import './../style.scss';
import Stakebx from './../components/stakebxx';
import { render } from '@testing-library/react';
import { numberToCurrency } from '../utils';
import { STAKE_ADDRESS, STAKE_ADDRESS_BNC } from '../config/stake';
export default class Stake extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const BNCProps = Object.assign(
      { ...this.props },
      {
        token: this.props.tokenBNC,
        stake: this.props.stakeBNC,
        stakeData: this.props.stakeDataBNC,
        tokenPrice: this.props.tokenPriceBNC,
      }
    );
    const { tokenBalance, tokenBalanceBNC, decimalsMultiplier } = this.props;
    return (
      <div className="Stake">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Stakebx
              {...this.props}
              tokenLabel={'Spi'}
              stakeAddress={STAKE_ADDRESS}
              network={'eth'}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Stakebx
              {...BNCProps}
              tokenLabel={'GSpi'}
              stakeAddress={STAKE_ADDRESS_BNC}
              network={'bsc'}
            />
          </div>
        </div>

        <div className="stake-btn-outer row">
          <div className="col-md-4">
            <div className="stake-btm stake-btm-blue ">
              <img src="images/gf1.png" className="gf1"></img>
              <h6>
                <span>Pot: </span>
                0$ Spi
              </h6>
              <h6 className="participant">
                <div>
                  <span>Participant Fee</span>
                </div>
                0$ Spi
              </h6>

              <a className="btn-main">Coming soon</a>

              <div className="time">
                <div>
                  0<span>D</span>
                </div>
                <div>
                  0<span>H</span>
                </div>
                <div>
                  0<span>S</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stake-btm stake-btm-middle">
              <div className="heading">
                <h2 className="no-span">Last Winners</h2>
                <div className="lwinnerrowouter">
                  <div className="lwinnerrow">
                    <div className="blue">Spi Raffle</div>
                    <div className="token">
                      <a className="btn-main">Claim</a>
                      0x000...00X
                    </div>
                  </div>
                  <div className="lwinnerrow">
                    <div className="blue">GSpi Raffle</div>
                    <div className="token">
                      <a className="btn-main">Claim</a>
                      0x000...00X
                    </div>
                  </div>
                </div>
              </div>

              <div className="heading heading-btm">
                <h2 className="no-span">Balance</h2>
                <div className="lwinnerrowouter">
                  <div className="lwinnerrow">
                    <div>
                      {(tokenBalance &&
                        numberToCurrency(
                          '',
                          tokenBalance / decimalsMultiplier
                        )) ||
                        '0'}{' '}
                      <span>Spi</span>
                    </div>
                    <div>
                      {(tokenBalanceBNC &&
                        numberToCurrency(
                          '',
                          tokenBalanceBNC / decimalsMultiplier
                        )) ||
                        '0'}{' '}
                      <span>GSpi</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stake-btm stake-btm-blue">
              <img src="images/gf2.png" className="gf2"></img>
              <h6>
                <span>Pot: </span>
                0$ GSpi
              </h6>
              <h6 className="participant">
                <div>
                  <span>Participant Fee</span>
                </div>
                0$ GSpi
              </h6>

              <a className="btn-main">Coming soon</a>

              <div className="time">
                <div>
                  0<span>D</span>
                </div>
                <div>
                  0<span>H</span>
                </div>
                <div>
                  0<span>S</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
