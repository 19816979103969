import React, { Component } from 'react';
import Web3 from 'web3';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Home from './views/home';
import Stake from './views/stake';
import './responsive.scss';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { RAFFLE_ABI, RAFFLE_ADDRESS } from './config/raffle';
import { STAKE_ABI, STAKE_ADDRESS, STAKE_ADDRESS_BNC } from './config/stake';
import { TOKEN_ABI, TOKEN_ADDRESS } from './config/token';
import { TOKEN_BNC_ABI, TOKEN_BNC_ADDRESS } from './config/tokenBNC';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      account: '',
      token: null,
      tokenPrice: null,
      tokenPriceBNC: null,
      raffle: null,
      stake: null,
      tokenBalance: null,
      decimals: 18,
      decimalsMultiplier: 1e18,
      stakeData: {
        estimatedEarnings: null,
      },
      tokenBNC: null,
      stakeBNC: null,
      stakeDataBNC: null,
      tokenBalanceBNC: null,
    };
  }

  componentDidMount() {
    this.loadBlockchainData();
    this.loadPrice();
  }

  async loadBlockchainData() {
    if (!window.ethereum || !window.ethereum.isConnected) return false;

    const web3 = new Web3(Web3.givenProvider);
    const accounts = await web3.eth.getAccounts();
    this.setState({ connected: !!accounts[0], account: accounts[0] });

    this.syncBlockchainData();
    this.interval = setInterval(() => {
      this.syncBlockchainData();
    }, 5000);

    this.syncBNCBlockchainData();
    this.intervalBNC = setInterval(() => {
      this.syncBNCBlockchainData();
    }, 5000);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
    if (this.intervalBNC) clearInterval(this.intervalBNC);
  }

  async syncBlockchainData() {
    // raffle,
    const { stake, token, account } = this.state;
    if (!account) return;

    if (!stake || !token) {
      const web3 = new Web3(Web3.givenProvider);
      const s = new web3.eth.Contract(STAKE_ABI, STAKE_ADDRESS);
      const t = new web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);

      this.setState({ stake: s, token: t });
      return;
    }

    console.log('syncing ethereum data...');
    try {
      const tokenBalance = await token.methods
        .balanceOf(account)
        .call({ from: account });

      this.setState({ tokenBalance });

      // Sync stake data
      const estimatedEarnings = await stake.methods
        .estimateDividendsOf(account)
        .call({ from: account });

      const { 0: pool, 1: supply } = await stake.methods
        .getGlobalInfo()
        .call({ from: account });

      const stakedTokens = await stake.methods
        .stakedOf(account)
        .call({ from: account });

      const stakeData = {
        estimatedEarnings,
        globalInfo: { pool, supply },
        stakedTokens,
      };

      this.setState({ stakeData });
    } catch (_) {
      console.log('failed to sync with ethereum network');
    }
  }

  async syncBNCBlockchainData() {
    const { stakeBNC, tokenBNC, account } = this.state;

    if (!account) return;

    if (!stakeBNC || !tokenBNC) {
      const web3 = new Web3(Web3.givenProvider);
      const tBNC = new web3.eth.Contract(TOKEN_BNC_ABI, TOKEN_BNC_ADDRESS);
      const sBNC = new web3.eth.Contract(STAKE_ABI, STAKE_ADDRESS_BNC);

      this.setState({ stakeBNC: sBNC, tokenBNC: tBNC });
      return;
    }

    console.log('syncing bsc data...');
    try {
      const tokenBalanceBNC = await tokenBNC.methods
        .balanceOf(account)
        .call({ from: account });

      this.setState({ tokenBalanceBNC });

      // Sync stake data
      const estimatedEarnings = await stakeBNC.methods
        .estimateDividendsOf(account)
        .call({ from: account });

      const {
        0: pool,
        1: supply,
      } = await stakeBNC.methods.getGlobalInfo().call({ from: account });

      const stakedTokens = await stakeBNC.methods
        .stakedOf(account)
        .call({ from: account });

      const stakeDataBNC = {
        estimatedEarnings,
        globalInfo: { pool, supply },
        stakedTokens,
      };

      this.setState({ stakeDataBNC });
    } catch (_) {
      console.log('failed to sync with bsc network');
    }
  }

  async loadPrice() {
    const res = await fetch(
      'https://api.coingecko.com/api/v3/coins/ethereum/contract/0x9b02dd390a603add5c07f9fd9175b7dabe8d63b7'
    );
    const json = await res.json();

    if (!json?.tickers) return;

    if (json?.tickers[0]?.converted_last?.usd) {
      this.setState({ tokenPrice: json.tickers[0].converted_last.usd });
    }
  }

  render() {
    const { connected, raffle, stake } = this.state;
    return (
      <Router>
        <div className="App">
          <Navbar connected={connected} />
          <div className="main-sec">
            <Switch>
              <Route path="/stake">
                <Stake {...this.state} />
              </Route>
              <Route path="/">
                <Home {...this.state} />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
