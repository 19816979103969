import React from 'react';
import ReactDOM from 'react-dom';
import { numberToCurrency } from '../utils';
import './../style.scss';
export default ({
  tokenBalance,
  stake,
  stakeBNC,
  account,
  decimalsMultiplier,
  stakeData,
  tokenPrice,
  stakeDataBNC,
  tokenBalanceBNC,
}) => {
  return (
    <div className="Home">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="blue-bx blue-bx-lg">
            <h3>Staking Rewards</h3>
            <img src="images/cart.png"></img>
            <div className="info">
              <h3>
                {(stakeData &&
                  stakeData.estimatedEarnings &&
                  numberToCurrency(
                    '',
                    stakeData.estimatedEarnings / decimalsMultiplier
                  )) ||
                  '0'}
              </h3>
              $Spi to Claim
            </div>

            <div className="info">
              <h5>
                {(tokenBalance &&
                  numberToCurrency('', tokenBalance / decimalsMultiplier)) ||
                  '0'}
              </h5>
              $Spi in Wallet
            </div>
            <a
              className="btn-main"
              onClick={(e) => {
                e.preventDefault();

                stake.methods
                  .claimEarning()
                  .send({ from: account })
                  .once('receipt', (receipt) => {
                    console.log(receipt);
                  });
              }}
            >
              Claim Dividends
            </a>
            <img src="images/cart-back.png" className="back"></img>
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="blue-bx blue-bx-lg">
            <h3>Staking Rewards</h3>
            <img src="images/cart.png"></img>
            <div className="info">
              <h3>
                {(stakeDataBNC &&
                  stakeDataBNC.estimatedEarnings &&
                  numberToCurrency(
                    '',
                    stakeDataBNC.estimatedEarnings / decimalsMultiplier
                  )) ||
                  '0'}
              </h3>
              $GSpi to Claim
            </div>

            <div className="info">
              <h5>
                {(tokenBalanceBNC &&
                  numberToCurrency('', tokenBalanceBNC / decimalsMultiplier)) ||
                  '0'}
              </h5>
              $GSpi in Wallet
            </div>
            <a
              className="btn-main"
              onClick={(e) => {
                e.preventDefault();

                stakeBNC.methods
                  .claimEarning()
                  .send({ from: account })
                  .once('receipt', (receipt) => {
                    console.log(receipt);
                  });
              }}
            >
              Claim Dividends
            </a>
            <img src="images/cart-back.png" className="back"></img>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <div className="blue-bx blue-bx-sm">
            Earn up to <br />
            <h2>
              {(stakeData &&
                stakeData.globalInfo &&
                stakeData.globalInfo.supply &&
                stakeData.globalInfo.pool &&
                numberToCurrency(
                  '',
                  (stakeData.globalInfo.pool / stakeData.globalInfo.supply) *
                    0.165 *
                    365
                )) ||
                '0'}
              % APY
            </h2>
            Staking $Spi
            <img src="images/sm1.png"></img>
          </div>
        </div>

        <div className="col-md-4 col-sm-12">
          <div className="blue-bx blue-bx-power">
            <div className="blue">EARN</div>
            <div className="black">$Spi Token</div>
            <div className="blue">Staking $Spi </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-12">
          <div className="blue-bx blue-bx-sm">
            Raffle with <br />
            <h2>$0</h2>
            Up for grabs
            <img src="images/sm3.png"></img>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="blue-bx blue-bx-stats">
            <h2>Stats</h2>
            <div className="drow">
              <div>Total Spi Supply</div>
              <b>
                {numberToCurrency('', 1000000)} /{' $ '}
                {(tokenPrice && numberToCurrency('', 1000000 * tokenPrice)) ||
                  '0'}
              </b>
            </div>
            <div className="drow">
              <div>Total Staked Spi</div>
              <b>
                {(stakeData &&
                  stakeData.globalInfo &&
                  stakeData.globalInfo.supply &&
                  numberToCurrency(
                    '',
                    stakeData.globalInfo.supply / decimalsMultiplier
                  )) ||
                  '0'}{' '}
                /{' $ '}
                {(stakeData &&
                  stakeData.globalInfo &&
                  stakeData.globalInfo.supply &&
                  tokenPrice &&
                  numberToCurrency(
                    '',
                    (stakeData.globalInfo.supply / decimalsMultiplier) *
                      tokenPrice
                  )) ||
                  '0'}
              </b>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="blue-bx blue-bx-sm">
            Total Staked Tokens (TST)
            <br />
            <h2>
              $
              {(stakeData &&
                stakeData.globalInfo &&
                stakeData.globalInfo.supply &&
                tokenPrice &&
                numberToCurrency(
                  '',
                  (stakeData.globalInfo.supply / decimalsMultiplier) *
                    tokenPrice
                )) ||
                '0'}
            </h2>
            <small>Across every Pools.</small>
          </div>
        </div>
      </div>
    </div>
  );
};
