import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { numberToCurrency } from '../utils';
import './../style.scss';

class Stakebx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stakingAmount: '',
      unstakingAmount: '',
    };
  }
  render() {
    const {
      token,
      stake,
      account,
      decimalsMultiplier,
      stakeData,
      tokenPrice,
      tokenLabel,
      stakeAddress,
      network,
    } = this.props;

    const { stakingAmount, unstakingAmount, staking, unstaking } = this.state;
    return (
      <div className="stake-bx">
        <img src="images/stakel.png" className="stakel"></img>
        <div className="blue-bx">
          <div className="header">
            <div className="headerbtm">
              <h5>Rewards are being taken offline, please unstake</h5>
              {(stakeData &&
                stakeData.globalInfo &&
                stakeData.globalInfo.pool &&
                numberToCurrency(
                  '',
                  stakeData.globalInfo.pool / decimalsMultiplier
                )) ||
                '0'}{' '}
              {tokenLabel}{' '}
              <b>
                {' '}
                /{' '}
                {(stakeData &&
                  stakeData.globalInfo &&
                  stakeData.globalInfo.pool &&
                  tokenPrice &&
                  numberToCurrency(
                    '',
                    (stakeData.globalInfo.pool / decimalsMultiplier) *
                      tokenPrice
                  )) ||
                  '-'}{' '}
                $
              </b>
            </div>
            <div className="headerbtm">
              <h5>Your staked tokens</h5>
              {(stakeData &&
                stakeData.stakedTokens &&
                numberToCurrency(
                  '',
                  stakeData.stakedTokens / decimalsMultiplier
                )) ||
                '0'}{' '}
              {tokenLabel}{' '}
              <b>
                {' '}
                /{' '}
                {(stakeData &&
                  stakeData.stakedTokens &&
                  tokenPrice &&
                  numberToCurrency(
                    '',
                    (stakeData.stakedTokens / decimalsMultiplier) * tokenPrice
                  )) ||
                  '-'}{' '}
                $
              </b>
            </div>
          </div>

          <div className="ip_outer">
            <div className="ip_row">
              <div className="form-control-outer">
                <input
                  className="form-control"
                  placeholder="Enter amount"
                  type="number"
                  value={stakingAmount}
                  onChange={(e) => {
                    this.setState({ stakingAmount: e.target.value });
                  }}
                ></input>
              </div>
              <a
                className="btn-main"
                onClick={(e) => {
                  e.preventDefault();
                  if (true) {
                    alert('Staking disabled');
                    return;
                  }
                  if (staking || !stakingAmount || !token || !stake || !account)
                    return;

                  const parsedAmount = stakingAmount * decimalsMultiplier;

                  const go = async () => {
                    const allowance = await token.methods
                      .allowance(account, stakeAddress)
                      .call({ from: account });

                    if (parsedAmount <= parseInt(allowance, 10)) {
                      stake.methods
                        .stake(parsedAmount.toString())
                        .send({ from: account })
                        .once('receipt', (receipt) => {
                          console.log(receipt);
                        });
                    } else {
                      token.methods
                        .approve(stakeAddress, parsedAmount.toString())
                        .send({ from: account })
                        .once('receipt', (receipt) => {
                          console.log(receipt);
                          stake.methods
                            .stake(parsedAmount.toString())
                            .send({ from: account })
                            .once('receipt', (receipt) => {
                              console.log(receipt);
                            });
                        });
                    }
                  };
                  go();
                }}
              >
                Under maintenance
              </a>
            </div>
            <div className="ip_row">
              <div className="form-control-outer">
                <input
                  className="form-control"
                  placeholder="Enter amount"
                  type="number"
                  value={unstakingAmount}
                  onChange={(e) => {
                    this.setState({ unstakingAmount: e.target.value });
                  }}
                ></input>
              </div>
              <a
                className="btn-main btn-main-blue"
                onClick={(e) => {
                  e.preventDefault();
                  if (unstaking || !unstakingAmount || !stake || !account)
                    return;

                  const parsedAmount = (
                    unstakingAmount * decimalsMultiplier
                  ).toString();

                  stake.methods
                    .unstake(parsedAmount)
                    .send({ from: account })
                    .once('receipt', (receipt) => {
                      console.log(receipt);
                    });
                }}
              >
                Unstake
              </a>
            </div>
            <div className="ip_row">
              <p style={{ color: 'white', marginBottom: 0 }}>
                Stake {tokenLabel} to earn {tokenLabel} through a weekly alloted
                pool. No minimum or maximum requirements. No penalties. Rewards
                are dynamic.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stakebx;
