import React from 'react';
import ReactDOM from 'react-dom';
import Web3 from 'web3';
import './../style.scss';
import { BrowserRouter as Router, Link } from 'react-router-dom';
class Navbar extends React.Component {
  render() {
    const { connected } = this.props;
    return (
      <nav className="navbar navbar-expand-md">
        <a className="navbar-brand" href="#">
          <img src="images/logo.png" className="nav-logo "></img>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/stake">
                Stake
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="#">
                VIP Stake
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://spi.club/">
                Spi.club
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="http://Shopping.io">
                Shopping.io
              </a>
            </li>
            <li className="nav-item">
              <a
                className=" nav-link btn-main"
                onClick={async (e) => {
                  e.preventDefault();
                  if (!window.ethereum) {
                    alert('You need metamask to interact with this site');
                  }
                  const res = await window.ethereum.send('eth_requestAccounts');
                  if (res && !!res.result) window.location.reload(false);
                  else alert('Failed to connect to metamask');
                }}
              >
                {connected ? 'Connected' : 'Connect'}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
